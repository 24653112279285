<template>
  <div>
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './header.vue'
import Footer from './footer.vue'
import { debounce } from '../../../../lvya-common/src/utils/DebounceThrottle.js'
import { mapState } from 'vuex'
import portalApi from '../../../../lvya-common/src/api/eos/portal'

export default {
  components: {
    Header,
    Footer,
  },
  props: {},
  data() {
    return {
      portalSchoolInfo: null,
    }
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav
    })
  },
  methods: {
    fnSetBaseFontSize() {
      const docEl = document.documentElement
      let clientWidth = docEl.clientWidth
      if (!clientWidth) return
      if (clientWidth >= 1440) {
        docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px'
      } else if (clientWidth >= 1024) {
        docEl.style.fontSize = 90 * (clientWidth / 1504) + 'px'
      } else {
        docEl.style.fontSize = 120 * (clientWidth / 750) + 'px'
      }
    },
    // 导航栏
    async getNavList() {
      this.portalSchoolInfo = JSON.parse(localStorage.getItem('portalSchoolInfo'))
      let res = { id: '7', type: '1', }
      let resData = await portalApi.getNav(res)

      // 去除sort为0的项，这一项作为首页图片一栏使用
      resData.forEach(item => {
        item.stypes = item.stypes.filter(subItem => subItem.sort != 0)
      })
      console.log({ resData })
      this.$store.commit('setYzsyNav', resData)
    },
    async getSidebar() {
      let res = { type: '2', pageNum: '1', pageSize: '99999', }
      let resData = await portalApi.getTypeListByPage(res)
      const asideNav = resData.list.map(item => ({ ...item, path: item.tzurl }))
      this.$store.commit('setYzsySideBar', asideNav)
    }
  },
  async created() {
    this.fnSetBaseFontSize()
    window.addEventListener('resize', debounce((e) => {
      this.fnSetBaseFontSize()
    }, 50))
    this.getSidebar()
    await this.getNavList()
  },
  mounted() {
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
  },
}
</script>

<style lang="less" scoped>
@import '../style/index.css';
/* @import url(); 引入css类 */
// .bluePcFrame {
//   width: 100%;
//   min-height: 100vh;
//   // background: url('../../../../../../public/static/ershi/contentBg.png');
// }
</style>
