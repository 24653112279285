var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head",class:{
    headFix: _vm.isHeadFix,
  }},[_c('div',{staticClass:"phNavIcon",class:{ on: _vm.isNavShow },on:{"click":function($event){$event.stopPropagation();return _vm.handlePhNavIconClick.apply(null, arguments)}}}),_c('div',{staticClass:"logo"},[_c('a',{staticClass:"pc-logo",on:{"click":function($event){return _vm.$router.push('/portal')}}},[_c('img',{attrs:{"src":require("../images/loginNew1.png"),"alt":""}})]),_c('a',{staticClass:"md-logo",on:{"click":function($event){return _vm.$router.push('/portal')}}},[(_vm.isMobile)?_c('img',{attrs:{"src":require("../images/loginNew2.png"),"alt":""}}):_c('img',{attrs:{"src":require("../images/loginNew2.png"),"alt":""}})])]),_c('div',{staticClass:"nav",style:({
      display: !_vm.isMobile || _vm.isNavShow ? 'block' : 'none',
    })},[_c('ul',_vm._l((_vm.nav),function(item,index){return _c('li',{key:index,staticClass:"on nav_list",on:{"mouseover":function($event){return _vm.handleMouseOver(index)},"mouseleave":function($event){return _vm.handleMouseLeave(index)},"click":function($event){$event.stopPropagation();return _vm.handleNavClick(index)}}},[(item.href)?_c('span',[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.$router.push(item.href)}}},[_vm._v(_vm._s(item.title))])]):_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]),(item.list.length)?_c('div',{staticClass:"nav_list",style:({
            display: _vm.showNavList && _vm.currentIndex === index ? 'block' : 'none',
          })},_vm._l((item.list),function(subItem,subIndex){return _c('p',{key:subIndex},[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.$router.push(subItem.href)}}},[_vm._v(_vm._s(subItem.title))])])}),0):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }